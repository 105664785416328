import { IMGPROXY_URL } from '@obeta/utils/lib/config'
import { useEffect, useRef } from 'react'
import { trackCustom } from '@obeta/utils/lib/tracking'

const RESIZING_TYPE = 'fit'

export const DEFAULT_WIDTH_MOBILE = 1440
export const DEFAULT_WIDTH_TABLET = 1632
export const DEFAULT_WIDTH_TABLET_WIDE = 704
export const DEFAULT_WIDTH_DESKTOP = 800

export const useImgProxyUrls = ({
  url,
  mobileWidth = DEFAULT_WIDTH_MOBILE,
  tabletWidth = DEFAULT_WIDTH_TABLET,
  tabletWideWidth = DEFAULT_WIDTH_TABLET_WIDE,
  desktopWidth = DEFAULT_WIDTH_DESKTOP,
  heightInPx,
}: {
  url: string
  mobileWidth?: number
  tabletWidth?: number
  tabletWideWidth?: number
  desktopWidth?: number
  heightInPx?: number | null
}) => {
  const didTrackMissingUrlAction = useRef(false)
  useEffect(() => {
    if (!url && !didTrackMissingUrlAction.current) {
      trackCustom('image-with-empty-url-rendered')
      didTrackMissingUrlAction.current = true
    }
  }, [url])

  const createHeightBasedImageProxyUrl = () =>
    `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}/height:${heightInPx}${path}`

  const path = `/plain/${url}`

  const imgProxyUrlMobile = heightInPx
    ? createHeightBasedImageProxyUrl()
    : `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${mobileWidth}${path}`
  const imgProxyUrlTablet = heightInPx
    ? createHeightBasedImageProxyUrl()
    : `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${tabletWidth}${path}`
  const imgProxyUrlTabletWide = heightInPx
    ? createHeightBasedImageProxyUrl()
    : `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${tabletWideWidth}${path}`
  const imgProxyUrlDesktop = heightInPx
    ? createHeightBasedImageProxyUrl()
    : `${IMGPROXY_URL}resizing_type:${RESIZING_TYPE}/rs:${RESIZING_TYPE}:${desktopWidth}${path}`

  return {
    imgProxyUrlMobile,
    imgProxyUrlTablet,
    imgProxyUrlTabletWide,
    imgProxyUrlDesktop,
  }
}
