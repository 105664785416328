import React, { FC, ImgHTMLAttributes, useState } from 'react'
import {
  DEFAULT_WIDTH_DESKTOP,
  DEFAULT_WIDTH_MOBILE,
  DEFAULT_WIDTH_TABLET,
  DEFAULT_WIDTH_TABLET_WIDE,
  useImgProxyUrls,
} from '@obeta/data/lib/hooks/useImgProxyUrls'
import { ReactComponent as CardProductPlaceholder } from '@obeta/assets/img/CardProductPlaceholder.svg'
import { removeDuplicates } from '@obeta/utils/lib/array'
import { useTheme } from '@mui/material'

export interface ImgProxyImage
  extends Omit<
    React.DetailedHTMLProps<ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
    'height' | 'width'
  > {
  url: string
  title?: string
  mobileWidth?: number
  tabletWidth?: number
  tabletWideWidth?: number
  desktopWidth?: number
  variant?: 'product' | 'base'
}

type ImgProxyImageProps =
  | (ImgProxyImage & { widthInPx: number | null | undefined; heightInPx?: never })
  | (ImgProxyImage & { heightInPx: number | null | undefined; widthInPx?: never })
  | (ImgProxyImage & {
      widthInPx?: never
      heightInPx?: never
    })

export const ImgProxyImage: FC<ImgProxyImageProps> = (props) => {
  const {
    className,
    heightInPx,
    mobileWidth = props.widthInPx ?? DEFAULT_WIDTH_MOBILE,
    tabletWidth = props.widthInPx ?? DEFAULT_WIDTH_TABLET,
    tabletWideWidth = props.widthInPx ?? DEFAULT_WIDTH_TABLET_WIDE,
    desktopWidth = props.widthInPx ?? DEFAULT_WIDTH_DESKTOP,
    title,
    url,
    variant = 'base',
    widthInPx,
    ...restProps
  } = props

  const MOBILE_MIN_WIDTH = useTheme().breakpoints.values.xs
  const TABLET_MIN_WIDTH = useTheme().breakpoints.values.sm
  const TABLETWIDE_MIN_WIDTH = useTheme().breakpoints.values.md
  const DESKTOP_MIN_WIDTH = useTheme().breakpoints.values.lg

  const [imageStatus, setImageStatus] = useState<'loading' | 'complete' | 'notStarted' | 'error'>(
    'notStarted'
  )

  //  Get proxy URLs for the image in different sizes based on the viewport
  const { imgProxyUrlMobile, imgProxyUrlTablet, imgProxyUrlTabletWide, imgProxyUrlDesktop } =
    useImgProxyUrls({ url, mobileWidth, tabletWidth, tabletWideWidth, desktopWidth, heightInPx })

  // Prevent overwriting of the src attribute that will be set later using the imgProxyUrl values
  restProps.src && delete restProps.src

  /**
   * Specifies multiple versions of the image as well as there intrinsic width in pixels.
   * (Sizes attribute tells the browser which of these image to choose).
   */
  const getSrcSet = () => {
    const srcSet: string[] = []

    // Please note that srcSet can only handle width as an optional parameter - we don't actually need to set this if height is provided
    if (heightInPx) {
      return ''
    }

    // Sample usage:
    // <img
    //   srcset="elva-fairy-480w.jpg 480w, elva-fairy-800w.jpg 800w"
    //   sizes="(max-width: 600px) 480px,
    //          800px"
    //   src="elva-fairy-800w.jpg"
    //   alt="Elva dressed as a fairy" />

    // mobile
    srcSet.push(`${imgProxyUrlMobile}@webp ${mobileWidth}w`)
    srcSet.push(`${imgProxyUrlMobile}@avif ${mobileWidth}w`)
    srcSet.push(`${imgProxyUrlMobile}@png ${mobileWidth}w`)

    // tablet
    srcSet.push(`${imgProxyUrlTablet}@webp ${tabletWidth}w`)
    srcSet.push(`${imgProxyUrlTablet}@avif ${tabletWidth}w`)
    srcSet.push(`${imgProxyUrlTablet}@png ${tabletWidth}w`)

    // tabletWide
    srcSet.push(`${imgProxyUrlTabletWide}@webp ${tabletWideWidth}w`)
    srcSet.push(`${imgProxyUrlTabletWide}@avif ${tabletWideWidth}w`)
    srcSet.push(`${imgProxyUrlTabletWide}@png ${tabletWideWidth}w`)

    // desktop
    srcSet.push(`${imgProxyUrlDesktop}@webp ${desktopWidth}w`)
    srcSet.push(`${imgProxyUrlDesktop}@avif ${desktopWidth}w`)
    srcSet.push(`${imgProxyUrlDesktop}@png ${desktopWidth}w`)

    return removeDuplicates(srcSet).join(',')
  }

  let alt = ''
  if (imageStatus === 'complete') {
    alt = title ?? ''
  }

  /**
   * Sizes attribute tells the browser how to choose the best image size from the ones specified in srcSet.
   * (Only works in conjunction with srcSet!)
   */
  const getSizes = (height: number | undefined | null) => {
    const sizes: string[] = []

    if (height) {
      return ''
    } else {
      // media condition (viewport/screen area) + intended display/source size (image)
      sizes.push(`(min-width:${MOBILE_MIN_WIDTH}px) ${mobileWidth}px`)
      sizes.push(`(min-width:${TABLET_MIN_WIDTH}px) ${tabletWidth}px`)
      sizes.push(`(min-width:${TABLETWIDE_MIN_WIDTH}px) ${tabletWideWidth}px`)
      sizes.push(`(min-width:${DESKTOP_MIN_WIDTH}px) ${desktopWidth}px`)
      sizes.push(`${desktopWidth}px`) // fallback
    }

    return sizes.join(', ')
  }

  const handleError = () => {
    // Set the src attribute to the original URL
    restProps.src = url
    setImageStatus('error')
  }

  return imageStatus === 'notStarted' || imageStatus === 'complete' ? (
    <img
      id={url}
      // Smallest source size provided, srcSet handles the selection of larger images based on the screen size or device capabilities
      src={imgProxyUrlMobile}
      onError={handleError}
      onLoadStart={() => {
        setImageStatus('loading')
      }}
      onLoad={() => {
        setImageStatus('complete')
      }}
      className={className}
      alt={alt}
      srcSet={getSrcSet()}
      sizes={getSizes(heightInPx)}
      {...restProps}
    />
  ) : variant === 'product' ? (
    <CardProductPlaceholder className={className} />
  ) : null
}
